<template>
    <div class="inquiry_popup modal1">
        <div class="music_comment modal_box">
            <div class="music_comment_title modal_title">
                <div class="mo_space_header">
                    <div class="mo_header_box">
                        <a>
                            <img src="/media/images/icon_back_24.svg" @click="$emit('closeModal')">
                        </a>
                        <h4 style="margin-bottom:0px;">한줄평 수정</h4>
                    </div>
                </div>
            </div>
            <div class="comment_content on">
                <textarea
                    type="text"
                    maxlength="50"
                    placeholder="이 노래의 한줄평을 남겨보세요:)"
                    v-model="content"
                ></textarea>
                <p class="comment_length">{{ content.length }}<span>/50</span></p>
            </div>
            <div class="popup_bottom modal_bottom_btn">
                <button v-if="content.length > 0" @click="updateMusicComment()">수정</button>
                <button style="text-align:center;filter: brightness(60%);" v-else>수정</button>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from "@vue/composition-api";
import VueCookies from "vue-cookies";
import axios from "axios";

export default defineComponent({
  name: 'UpdateMusicComment',
  data () {
    return {
      content: ""
    };
  },
  props: {
    musicId: String,
    preContent: String
  },
  mounted () {
    this.content = this.preContent;
  },
  methods: {
    updateMusicComment () {
      var aToken = VueCookies.get("aToken");
      var headers = { "X-AUTH-TOKEN": aToken };
      var musicId = this.musicId;
      var userId = VueCookies.get("userId");
      var content = this.content;

      axios
        .post("/api/player/updateMusicComment", { musicId, userId, content }, { headers })
        .then(res => {
          if (res.data.resultCd === '0000') {
            this.$emit('closeModal2');
            this.$emit('updateCommentMo', musicId, content);
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
});
</script>

<style scoped>
  @media all and (max-width: 500px) {
    .modal_bottom_btn button {
      margin-bottom: 0;
    }
  }
  @media all and (min-width: 501px) {
    .mo_space_header {
      display: none;
    }
  }
</style>
