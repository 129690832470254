<template>
    <div class="wrap" style="overflow: auto">
        <Aside v-if="asideStatus" @closeAside="asideStatus = false" />
        <Comment v-if="isComment" :musicInfo="musicInfo" @closeModal="isComment = false" @removeComment="removeComment" @updateComment="updateComment" />
        <!-- <CancelLike v-if="isCancelLike" :musicNm="musicNm" :likeId="likeId" @closeModal="isCancelLike = false" @openModal="isFinishedCancel = true" />
    <FinishedCancel v-if="isFinishedCancel" :isAllSelect="isAllSelect" @closeModal="closeAlert()" /> -->
        <div class="space_pc" @click="hideTooltip2()">
            <section class="main">
                <div class="mymusic_box">
                    <div class="mymusic_box_title">
                        <h2>마이 뮤직</h2>
                        <div></div>
                        <div class="tooltip">
                            <!-- <span></span> -->
                            <p style="margin-bottom: 0px">
                                <span>마이뮤직이란?</span><br />
                                '좋아요'를 누른 곡을 모아볼 수 있는 메뉴입니다.
                            </p>
                        </div>
                        <div v-if="isSpaceListShow" class="select-box-area" @mouseover.once="clickSelectBox()">
                            <div class="label select-box">
                                <p class="label" style="margin-bottom: 0px" v-html="spaceNm" />
                            </div>
                            <div class="select-box-dropDown">
                                <ul class="optionList">
                                    <li class="optionItem" @click="getMusicCommentList(1)">
                                        <p>전체 스페이스</p>
                                    </li>
                                    <template>
                                        <li v-for="(item, index) in spaceList" class="optionItem" :key="index" @click="getMusicCommentListBySpace(1, item)">
                                            <p v-html="item.spaceNm" />
                                        </li>
                                    </template>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="group_tab_text">
                        <a @click="linkLike()">좋아요</a>
                        <a class="on" @click="linkComment()">한줄평</a>
                    </div>
                    <div class="brand_music_box">
                        <div class="mymusic_table_top">
                            <table class="mymusic_table">
                                <colgroup>
                                    <col width="4%" />
                                    <col width="42%" />
                                    <col width="50%" />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th colspan="2" style="text-align: left">곡명/가수명</th>
                                        <th style="text-align: left">한줄평</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                        <div class="scroll_body">
                            <table class="mymusic_table">
                                <colgroup>
                                    <col width="4%" />
                                    <col width="42%" />
                                    <col width="50%" />
                                </colgroup>
                                <tbody class="mymusic_table_box" v-if="total > 0">
                                    <template>
                                        <tr v-for="(item, index) in musicCommentList" :key="index">
                                            <td class="tbl_mymusic_tit">
                                                <p class="mymusic_img" style="margin-bottom: 0px"><img :src="item.albumImg" width="42px" alt="" /></p>
                                            </td>
                                            <td>
                                                <p class="mymusic_tit" style="margin-bottom: 0px">
                                                    <span v-html="item.musicNm"></span>
                                                    <span class="comment_count" @click="openMusicComment(item)" v-if="item.commentCountByMusic < 100">{{ item.commentCountByMusic }}</span>
                                                    <span class="comment_count" @click="openMusicComment(item)" v-else>100+</span>
                                                </p>
                                                <p class="artist" style="margin-bottom: 0px" v-html="item.artist"></p>
                                            </td>
                                            <td>
                                                <p style="color: #fff; cursor: pointer" v-html="item.content" @click="openMusicComment(item)"></p>
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                                <tbody class="mymusic_table_box" v-else>
                                    <tr class="empty_page">
                                        <td>
                                            <div><img src="/media/img/mymusic/icon_empty_comments.png" alt="" /></div>
                                            <p>작성한 '한줄평'이 없습니다.</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <pagination :pageSetting="pageDataSetting(total, limit, block, page)" @paging="pagingMethod" />
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <!-- 모바일 -->
        <div class="space_mo" @click="hideTooltip()">
            <header class="header mo_header active">
                <div class="header_box">
                    <div class="logo">
                        <a>
                            <img src="/media/images/icon_back_24.svg" @click="linkPlayer()" />
                            <h2>마이 뮤직</h2>
                            <div></div>
                            <div class="tooltip">
                                <!-- <span></span> -->
                                <p style="margin-bottom: 0px">
                                    <span>마이뮤직이란?</span><br />
                                    '좋아요'를 누른 곡을 모아볼 수 있는 메뉴입니다.
                                </p>
                            </div>
                        </a>
                    </div>
                    <div class="right_menu">
                        <div class="alarm_box">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" @click="mobileAlarm()">
                                <path d="M9.02759 19.7773C9.02759 20.5181 9.46002 21.9996 11.1897 21.9996C12.9195 21.9996 13.3519 20.5181 13.3519 19.7773" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M2.00024 18.666H20.3786" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M3.62183 18.1113V10.3335C3.62183 7.92613 5.13534 3.11133 11.1894 3.11133C17.2434 3.11133 18.7569 8.2965 18.7569 10.8891V18.1113" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <ellipse cx="17.6756" cy="6.44444" rx="4.32431" ry="4.44444" fill="#FF1150" v-if="$store.state.noticeCount !== 0" />
                            </svg>
                        </div>
                        <div class="toggle" @click="showAside">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_2231_20993)">
                                    <path d="M24 20L6 20L6 18L24 18L24 20ZM24 13L12 13L12 11L24 11L24 13ZM24 6L6 6L6 4L24 4L24 6Z" fill="white" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_2231_20993">
                                        <rect width="24" height="24" fill="white" transform="translate(24 24) rotate(180)" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                    </div>
                </div>
                <div class="group_tab_text">
                    <a @click="linkLike()">좋아요</a>
                    <a class="on" @click="linkComment()">한줄평</a>
                </div>
                <div class="mymusic_h select_box2">
                    <div v-if="isSpaceListShow" class="select-box-area" @mouseover.once="clickSelectBox2()">
                        <div class="label2 select-box">
                            <p class="label2" v-html="spaceNm" />
                        </div>
                        <div class="select-box-dropDown">
                            <ul class="optionList">
                                <li class="optionItem2" @click="getMusicCommentList(1)">
                                    <p>전체 스페이스</p>
                                </li>
                                <template>
                                    <li v-for="(item, index) in spaceList" class="optionItem2" :key="index" @click="getMusicCommentListBySpace(1, item)">
                                        <p v-html="item.spaceNm" />
                                    </li>
                                </template>
                            </ul>
                        </div>
                    </div>
                </div>
            </header>

            <section class="main payment">
                <div class="main_box">
                    <div class="mymusic_comment_content" v-if="total > 0">
                        <template>
                            <ul v-for="(item, index) in musicCommentList" :key="index">
                                <li>
                                    <div class="mo_music_comment">
                                        <p v-html="item.content" @click="openMusicComment(item)"></p>
                                    </div>
                                    <div class="music_info">
                                        <div class="album"><img :src="item.albumImg" alt="" /></div>
                                        <div class="musicNm_artist">
                                            <span class="mymusic_tit" v-html="item.musicNm"></span>
                                            <span class="artist" v-html="item.artist"></span>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="comment_count" @click="openMusicComment(item)" v-if="item.commentCountByMusic < 100">{{ item.commentCountByMusic }}</div>
                                    <div class="comment_count" @click="openMusicComment(item)" v-else>100+</div>
                                </li>
                            </ul>
                        </template>
                        <paginationMo :pageSetting="pageDataSetting(total, limit, blockMo, page)" @paging="pagingMethod" />
                    </div>
                    <div class="mymusic_content" v-else>
                        <ul class="empty_page">
                            <li>
                                <div><img src="/media/img/mymusic/icon_comments.png" alt="" /></div>
                                <p>작성한 '한줄평'이 없습니다.</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import Aside from '@/layout/aside/Aside.vue';
import pagination from '@/components/pagination/pagination.vue';
import paginationMo from '@/components/pagination/paginationMo.vue';
import Comment from '@/components/modal/MyMusic/Comment.vue';
// import CancelLike from "@/components/modal/MyMusic/CancelLike.vue";
// import FinishedCancel from "@/components/modal/MyMusic/FinishedCancel.vue";
import dayjs from 'dayjs';
import axios from '@/service/axios';
import VueCookies from 'vue-cookies';
import router from '@/router';
import $ from 'jquery';

export default {
  name: 'MyMusicComment',
  data () {
    return {
      spaceList: [],
      musicCommentList: [],
      asideStatus: false,
      spaceNm: '전체 스페이스',
      musicInfo: {},
      spaceId: null,
      // isCancelLike: false,
      // isFinishedCancel: false,
      isAllSelect: true,
      isComment: false,
      // 페이징
      page: 1,
      block: 10,
      blockMo: 5,
      limit: 10,
      total: 0,
      isSpaceListShow: true
    };
  },
  components: {
    Aside,
    // CancelLike,
    // FinishedCancel,
    pagination,
    paginationMo,
    Comment
  },
  async created () {
    await this.getMusicCommentList(1);
    await this.getSubscribeSpaceList();
  },
  mounted () {
    this.set();
  },
  methods: {
    updateComment (musicId, content) {
      var index = this.musicCommentList.findIndex((el) => el.musicId === musicId);
      this.musicCommentList[index].content = content;
      this.musicCommentList.push();
    },
    removeComment (musicId) {
      this.musicCommentList = this.musicCommentList.filter((el) => el.musicId !== musicId);
      this.total -= 1;
    },
    openMusicComment (music) {
      this.isComment = true;
      this.musicInfo = music;
    },
    linkComment () {
      router.push({ name: 'MyMusicComment' });
    },
    linkLike () {
      router.push({ name: 'MyMusic' });
    },
    set () {
      $('.mo_header .header_box .logo .tooltip').hide();
      $('.mymusic_box .mymusic_box_title .tooltip').hide();
    },
    resetLikeThumb () {
      $('.thumb_up img').attr('src', (index, attr) => {
        if (attr.match('_off.svg')) {
          return attr.replace('_off.svg', '_on.svg');
        }
      });
    },
    changeLike (item, e) {
      this.changeLikeMusic(item);
      $(e.currentTarget).attr('src', (index, attr) => {
        if (attr.match('_on.svg')) {
          return attr.replace('_on.svg', '_off.svg');
        } else {
          return attr.replace('_off.svg', '_on.svg');
        }
      });
    },
    changeLikeMusic (item) {
      var likeId = item.likeId;
      var aToken = VueCookies.get('aToken');
      var headers = { 'X-AUTH-TOKEN': aToken };

      axios.post('/api/player/updateLikeCancle', { likeId }, { headers }).catch((err) => {
        console.log(err);
      });
    },
    async getSubscribeSpaceList () {
      var userId = VueCookies.get('userId');
      var aToken = VueCookies.get('aToken');
      var headers = { 'X-AUTH-TOKEN': aToken };

      await axios
        .post('/api/player/selectSpaceListRegisteredMusicCommentByUser', { userId }, { headers })
        .then((res) => {
          if (res.data.resultCd === '0000') {
            console.log(Object.keys(res.data.result).includes('musicCommentList'));
            if (Object.keys(res.data.result).includes('musicCommentList')) {
              this.spaceList = res.data.result.musicCommentList;
            } else {
              this.isSpaceListShow = false;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    showTooltip () {
      $(document)
        .off('click')
        .on('click', '.mo_header .header_box .logo h2 svg', (e) => {
          $('.mo_header .header_box .logo .tooltip').fadeIn(300);
        });
    },
    hideTooltip () {
      $('.mo_header .header_box .logo .tooltip').fadeOut(300);
    },
    showTooltip2 () {
      $(document)
        .off('click')
        .on('click', '.mymusic_box .mymusic_box_title h2 svg', (e) => {
          $('.mymusic_box .mymusic_box_title .tooltip').fadeIn(300);
        });
    },
    hideTooltip2 () {
      $('.mymusic_box .mymusic_box_title .tooltip').fadeOut(300);
    },
    mobileAlarm () {
      this.$router.push({ name: 'Notice0' });
    },
    dateTime (value) {
      return dayjs(value).format('MM.DD HH:mm');
    },
    linkPlayer () {
      var spaceId = VueCookies.get('spaceId');
      if (spaceId != null) {
        router.push({ name: 'Player' });
      } else {
        router.push({ name: 'Main' });
      }
    },
    showAside () {
      this.asideStatus = true;
    },
    async getMusicCommentList (page) {
      if (page === 1) this.page = 1;
      this.isAllSelect = true;
      var aToken = VueCookies.get('aToken');
      var headers = { 'X-AUTH-TOKEN': aToken };
      var userId = VueCookies.get('userId');
      var limit = this.limit;
      var offset = (page - 1) * this.limit;

      await axios
        .post('/api/player/selectMusicCommentListByUser', { userId, limit, offset }, { headers })
        .then((res) => {
          if (res.data.resultCd === '0000') {
            var result = res.data.result;
            this.musicCommentList = result.musicCommentList;
            this.total = result.totalCount;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getMusicCommentListBySpace (page, space) {
      if (page === 1) this.page = 1;
      this.isAllSelect = false;
      var aToken = VueCookies.get('aToken');
      var headers = { 'X-AUTH-TOKEN': aToken };
      if (space != null) {
        this.spaceId = space.spaceId;
      }
      var spaceId = this.spaceId;
      var limit = this.limit;
      var offset = (page - 1) * this.limit;

      await axios
        .post('/api/player/selectMusicCommentListBySpace', { spaceId, limit, offset }, { headers })
        .then((res) => {
          if (res.data.resultCd === '0000') {
            var result = res.data.result;
            this.musicCommentList = result.musicCommentList;
            this.total = result.totalCount;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    pagingMethod (page) {
      this.isAllSelect ? this.getMusicCommentList(page) : this.getMusicCommentListBySpace(page);
      this.page = page;
      this.pageDataSetting(this.total, this.limit, this.block, this.page);
    },
    pageDataSetting (total, limit, block, page) {
      const totalPage = Math.ceil(total / limit);
      const currentPage = page;
      const first = currentPage > 1 ? parseInt(currentPage, 10) - parseInt('1', 10) : null;
      const end = totalPage !== currentPage ? parseInt(currentPage, 10) + parseInt('1', 10) : null;

      const startIndex = (Math.ceil(currentPage / block) - 1) * block + 1;
      const endIndex = startIndex + block > totalPage ? totalPage : startIndex + block - 1;
      const list = [];
      for (let index = startIndex; index <= endIndex; index++) {
        list.push(index);
      }
      return { first, end, list, currentPage };
    },
    clickSelectBox () {
      /* select_box_custom */
      var label = document.querySelectorAll('.label');
      label.forEach(function (lb) {
        lb.addEventListener('click', (e) => {
          var optionList = lb.nextElementSibling;
          var optionItems = optionList.querySelectorAll('.optionItem');
          clickLabel(lb, optionItems);
        });
      });
      var clickLabel = (lb, optionItems) => {
        //* ***************** 추가된 부분 START
        // 내가 아닌 다른 select 닫기
        label.forEach(function (itemLb) {
          if (lb !== itemLb) {
            itemLb.parentNode.classList.remove('active');
          }
        });
        //* ***************** 추가된 부분 END
        if (lb.parentNode.classList.contains('active')) {
          lb.parentNode.classList.remove('active');
          optionItems.forEach((opt) => {
            opt.removeEventListener('click', () => {
              handleSelect(lb, opt);
            });
          });
        } else {
          lb.parentNode.classList.add('active');
          // console.log(lb.parentNode.classList);
          optionItems.forEach((opt) => {
            opt.addEventListener('click', () => {
              handleSelect(lb, opt);
            });
          });
        }
      };
      var handleSelect = (label, item) => {
        this.spaceNm = item.textContent;
        // label.innerHTML = item.textContent;
        label.parentNode.classList.remove('active');
      };
      var handleClose = (e) => {
        // select 가 아닌경우 닫기
        if (!e.target.classList.contains('label') && !e.target.classList.contains('optionItem')) {
          label.forEach(function (lb) {
            lb.parentNode.classList.remove('active');
          });
        }
      };
      window.addEventListener('click', (e) => handleClose(e));
    },
    clickSelectBox2 () {
      /* select_box_custom */
      var label = document.querySelectorAll('.label2');
      label.forEach(function (lb) {
        lb.addEventListener('click', (e) => {
          var optionList = lb.nextElementSibling;
          var optionItems = optionList.querySelectorAll('.optionItem2');
          clickLabel(lb, optionItems);
        });
      });
      var clickLabel = (lb, optionItems) => {
        //* ***************** 추가된 부분 START
        // 내가 아닌 다른 select 닫기
        label.forEach(function (itemLb) {
          if (lb !== itemLb) {
            itemLb.parentNode.classList.remove('active');
          }
        });
        //* ***************** 추가된 부분 END
        if (lb.parentNode.classList.contains('active')) {
          lb.parentNode.classList.remove('active');
          optionItems.forEach((opt) => {
            opt.removeEventListener('click', () => {
              handleSelect(lb, opt);
            });
          });
        } else {
          lb.parentNode.classList.add('active');
          // console.log(lb.parentNode.classList);
          optionItems.forEach((opt) => {
            opt.addEventListener('click', () => {
              handleSelect(lb, opt);
            });
          });
        }
      };
      var handleSelect = (label, item) => {
        this.spaceNm = item.textContent;
        // label.innerHTML = item.textContent;
        label.parentNode.classList.remove('active');
      };
      var handleClose = (e) => {
        // select 가 아닌경우 닫기
        if (!e.target.classList.contains('label2') && !e.target.classList.contains('optionItem2')) {
          label.forEach(function (lb) {
            lb.parentNode.classList.remove('active');
          });
        }
      };
      window.addEventListener('click', (e) => handleClose(e));
    }
  }
};
</script>

<style scoped>
.mymusic_box .mymusic_box_title .tooltip {
  position: relative;
  position: absolute;
  /* width: 100%; */
  top: 180px;
  left: 12%;
  z-index: 2;
  background: #fd4e6d;
  border-radius: 4px;
  padding: 6px 10px 10px;
  opacity: 1;
  height: auto;
}

.mymusic_box .mymusic_box_title .tooltip > span {
  position: absolute;
  top: -4px;
  left: 13%;
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 2px;
  background: #fd4e6d;
  transform: rotate(45deg);
}

.mymusic_box .mymusic_box_title .tooltip p {
  color: #fff;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.4;
}

.mymusic_box .mymusic_box_title .tooltip p span {
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 5px;
  line-height: 2;
}

.mo_header .tooltip {
  position: relative;
  position: absolute;
  width: 100%;
  top: 82%;
  /* right: 18%; */
  z-index: 2;
  background: #fd4e6d;
  border-radius: 4px;
  padding: 6px 10px 10px;
  opacity: 1;
  height: auto;
}

.mo_header .tooltip > span {
  position: absolute;
  top: -4px;
  left: 30%;
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 2px;
  background: #fd4e6d;
  transform: rotate(45deg);
}

.mo_header .tooltip p {
  color: #fff;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.4;
}

.mo_header .tooltip p span {
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 5px;
  line-height: 2;
}

.mymusic_h.select_box2 .select-box-area .label2.select-box p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 16px;
  height: 52px;
  line-height: 52px;
}

.select-box-area .label.select-box p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 20px;
}
</style>
