<template>
    <div class="payment_paging paging_pc" style="width:100%" v-if="pageSetting.list.length">
        <div
            v-if="pageSetting.first !== null"
            @click="pageSetting.first !== null ? sendPage(pageSetting.first) : ''"
        >
            <img src="/media/img/pagenation_prev.png" alt="">
        </div>
        <span
            :class="{ on: page === pageSetting.currentPage }"
            v-for="page in pageSetting.list"
            :key="page"
            @click="sendPage(page)"
        >
            {{ page }}
        </span>
        <div
            v-if="pageSetting.end !== null"
            @click="pageSetting.end !== null ? sendPage(pageSetting.end) : ''"
        >
            <img src="/media/img/pagenation_next.png" alt="">
        </div>
    </div>
</template>

<script>
import { defineComponent } from "@vue/composition-api";
export default defineComponent({
  props: ["pageSetting"],
  methods: {
    sendPage (page) {
      this.$emit("paging", page);
    }
  }
});
</script>
