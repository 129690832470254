<template>
    <div class="single-file-alert alert">
        <div class="single-file-box alert_box">
            <h3 style="padding-bottom: 12px;">{{ musicNm }}</h3>
            <h3 style="padding-top: 0px;">의 한줄평을 삭제할까요?</h3>
            <div class="single-file-btn">
                <p @click="$emit('closeModal')">아니오</p>
                <p id="color-text" @click="[$emit('removeComment'), $emit('closeModal')]">네</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'removeMusicComment',
  props: {
    musicNm: String
  }
};
</script>
